<template>
  <b-collapse class="panel" animation="slide">
    <template #trigger="props">
      <div class="panel-heading">
        <h2>{{ $tc("device", 2) }}</h2>
        <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
      </div>
    </template>
    <div class="panel-block">
      <b-button
        v-if="contract"
        icon-left="plus"
        @click="showDeviceModal = true"
      >
        {{ $t("device_add") }}
      </b-button>
      <b-table
        :data="devices"
        :loading="loading"
        default-sort="serialNumber"
        :class="{ 'mt-4': contract }"
      >
        <template #empty>
          {{ loading ? $t("loading") : $t("no_matching_results") }}
        </template>

        <b-table-column
          :label="$t('serial_number')"
          field="serialNumber"
          sortable
        >
          <template v-slot="props">
            {{ props.row.serialNumber }}
          </template>
        </b-table-column>

        <b-table-column :label="$tc('product')" field="product.name" sortable>
          <template v-slot="props">
            {{ props.row.product.name }}
          </template>
        </b-table-column>

        <b-table-column :label="$t('status')" field="status.name" sortable>
          <template v-slot="props">
            {{ props.row.status.name }}
          </template>
        </b-table-column>

        <b-table-column v-if="contract && userHasRoleSuperAdmin" numeric>
          <template v-slot="props">
            <b-button
              icon
              size="is-small"
              type="is-text"
              @click="remove(props.row)"
            >
              <b-icon icon="close" size="is-small"></b-icon>
            </b-button>

            <b-button
              icon
              size="is-small"
              type="is-text"
              tag="router-link"
              :to="{ name: 'DeviceView', params: { id: props.row.id } }"
            >
              <b-icon icon="arrow-right" size="is-small"></b-icon>
            </b-button>
          </template>
        </b-table-column>
      </b-table>
    </div>

    <b-modal v-model="showDeviceModal" :width="400" scroll="keep">
      <DeviceAddModal
        v-if="userHasRoleSuperAdmin"
        :contract="contract"
        @submit="$emit('submit')"
      ></DeviceAddModal>
    </b-modal>
  </b-collapse>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DeviceAddModal from "./DeviceAddModal";

export default {
  components: { DeviceAddModal },
  props: {
    company: {
      type: Object,
      default: null,
    },
    contract: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      devices: [],
      showDeviceModal: false,
    };
  },
  computed: {
    ...mapGetters(["findDeviceStatusByUri", "userHasRoleSuperAdmin"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getAllDevices", "updateContract"]),
    async load() {
      this.loading = true;

      if (this.contract) {
        for (const device of this.contract.devices) {
          const devices = [...this.contract.devices];
          device.status = this.findDeviceStatusByUri(device.status["@id"]);
          this.devices = devices;
        }
      }

      if (this.company) {
        this.devices = await this.getAllDevices({
          assignedTo: this.company["@id"],
        });
      }

      this.loading = false;
    },
    remove(device) {
      this.$buefy.dialog.confirm({
        title: this.$t("device_remove"),
        message: this.$t("device_remove_confirm"),
        cancelText: this.$t("cancel"),
        confirmText: this.$t("yes"),
        type: "is-danger",
        onConfirm: async () => {
          this.loading = true;
          const devices = this.contract.devices.filter(
            (d) => d.id !== device.id
          );
          await this.updateContract({
            id: this.contract.id,
            contract: { devices: devices.map((d) => d["@id"]) },
          });
          this.$emit("submit");
        },
      });
    },
  },
};
</script>
