<template>
  <b-collapse class="panel" animation="slide">
    <template #trigger="props">
      <div class="panel-heading">
        <h2>{{ $tc("partner", 2) }}</h2>
        <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
      </div>
    </template>
    <div class="panel-block">
      <b-table
        :data="partnerships"
        :loading="loading"
        default-sort="partnerRole.name"
      >
        <template #empty>
          {{ loading ? $t("loading") : $t("no_matching_results") }}
        </template>

        <b-table-column
          :label="$tc('partner_role')"
          field="partnerRole.name"
          sortable
        >
          <template v-slot="props">
            {{ props.row.partnerRole.name }}
          </template>
        </b-table-column>

        <b-table-column :label="$tc('company')" field="company.name" sortable>
          <template v-slot="props">
            {{ props.row.company.name }}
          </template>
        </b-table-column>

        <b-table-column :label="$tc('user')" field="user.name" sortable>
          <template v-slot="props">
            {{ props.row.user.name || "–" }}
          </template>
        </b-table-column>

        <b-table-column
          :visible="!!company"
          :label="$tc('contract')"
          field="contract.startDate"
          sortable
        >
          <template v-slot="props">
            {{ props.row.contract.company.name }}
            ({{ props.row.contract.startDate | date }} &ndash;
            {{ props.row.contract.endDate | date }})
          </template>
        </b-table-column>

        <b-table-column numeric>
          <template v-slot="props">
            <b-button
              icon
              size="is-small"
              type="is-text"
              tag="router-link"
              :to="{ name: 'PartnerView', params: { id: props.row.id } }"
            >
              <b-icon icon="arrow-right" size="is-small"></b-icon>
            </b-button>
          </template>
        </b-table-column>
      </b-table>
    </div>
  </b-collapse>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    company: {
      type: Object,
      default: null,
    },
    contract: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      partnerships: [],
    };
  },
  computed: {
    ...mapGetters(["findPartnerRoleByUri"]),
  },
  async mounted() {
    let partnerships = null;

    if (this.company) {
      partnerships = await this.getAllPartners({
        company: this.company.id,
      });
    }

    if (this.contract) {
      partnerships = await this.getAllPartners({
        contract: this.contract.id,
      });
    }

    for (const p of partnerships) {
      p.partnerRole = this.findPartnerRoleByUri(p.partnerRole);
    }

    this.partnerships = partnerships;

    this.loading = false;
  },
  methods: {
    ...mapActions(["getAllPartners"]),
  },
};
</script>
