<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        {{ $t("end_date_change") }}
      </div>
    </div>
    <div class="card-content">
      <form class="mt-2" @submit.prevent="submit">
        <CustomDatePicker
          v-model="endDate"
          :label="$t('end_date')"
          :min="contract.startDate"
          :horizontal="false"
        ></CustomDatePicker>

        <b-button
          type="is-primary"
          native-type="submit"
          class="mt-2"
          icon-left="content-save"
          :loading="submitting"
        >
          {{ $t("save") }}
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomDatePicker from "../../../components/forms/CustomDatePicker";

export default {
  components: { CustomDatePicker },
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      endDate: this.contract.endDate,
    };
  },
  computed: {
    ...mapGetters(["contractStatusesOrderedByName"]),
  },
  methods: {
    ...mapActions(["updateContract"]),
    submit() {
      this.submitting = true;
      this.updateContract({
        id: this.contract.id,
        contract: {
          endDate: this.endDate,
        },
      })
        .then(() => {
          this.$emit("submit");
          this.$parent.close();
        })
        .catch(() => {
          this.submitting = false;
        });
    },
  },
};
</script>
