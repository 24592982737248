<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        {{ $t("device_add") }}
      </div>
    </div>
    <div class="card-content">
      <form class="mt-2" @submit.prevent="submit">
        <b-field horizontal :label="$tc('device')" label-for="device">
          <b-select id="device" v-model="deviceId" required>
            <option
              v-for="d in devices"
              :key="d.id"
              :value="d['@id']"
              :disabled="d.activeContract"
            >
              {{ d.serialNumber }}
              {{ d.activeContract ? ` (${$t("in_active_contract")})` : "" }}
            </option>
          </b-select>
        </b-field>

        <b-button
          type="is-primary"
          native-type="submit"
          class="mt-3"
          icon-left="content-save"
          :loading="submitting"
        >
          {{ $t("save") }}
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import { sortBy } from "lodash-es";
import { mapActions } from "vuex";
import API from "../../../helpers/API";

export default {
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      fetching: false,
      devices: [],
      deviceId: null,
    };
  },
  async mounted() {
    this.getAllDevices().then((devices) => {
      this.devices = sortBy(devices, "serialNumber");
    });
  },
  methods: {
    ...mapActions(["getAllDevices", "updateContract", "updateDevice"]),
    async submit() {
      this.submitting = true;

      await this.updateDevice({
        id: API.stripId(this.deviceId),
        device: { assignedTo: null },
      });

      this.updateContract({
        id: this.contract.id,
        contract: {
          devices: [
            ...this.contract.devices.map((d) => d["@id"]),
            this.deviceId,
          ],
        },
      })
        .then(() => {
          this.$emit("submit");
          this.$parent.close();
        })
        .catch(() => {
          this.submitting = false;
        });
    },
  },
};
</script>
