<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div
      v-if="!loading"
      class="is-flex is-flex-wrap-wrap is-align-items-center my-4"
    >
      <h1 class="mr-5">
        <span>{{ $tc("contract", 2) }}</span>
      </h1>
      <b-button
        v-if="userHasRoleSuperAdmin"
        type="is-primary"
        icon-left="autorenew"
        @click="renew"
      >
        {{ $t("renew") }}
      </b-button>
    </div>

    <div v-if="!loading" class="card">
      <div class="card-content pt-3">
        <b-message
          v-if="expired"
          type="is-danger"
          aria-close-label="Close message"
        >
          {{ $t("expired") }}
        </b-message>

        <BackButton :parent-route="{ name: 'ContractIndex' }"></BackButton>

        <table class="table details-table">
          <tr>
            <th>{{ $t("contract_number") }}</th>
            <td>{{ contract.contractNumber }}</td>
          </tr>
          <tr v-if="userHasRoleSuperAdmin">
            <th>{{ $tc("company") }}</th>
            <td>
              <router-link
                :to="{
                  name: 'CompanyView',
                  params: { id: contract.company.id },
                }"
              >
                {{ contract.company.name }}
              </router-link>
            </td>
          </tr>
          <tr>
            <th>{{ $t("start_date") }}</th>
            <td>{{ contract.startDate | date }}</td>
          </tr>
          <tr>
            <th>{{ $t("end_date") }}</th>
            <td>
              {{ contract.endDate | date }}<br />
              <b-button
                v-if="userHasRoleSuperAdmin"
                icon-left="calendar-range"
                class="mt-1"
                @click="showContractEndDateModal = true"
              >
                {{ $t("end_date_change") }}
              </b-button>
            </td>
          </tr>
          <tr>
            <th>{{ $t("next_evaluation_date") }}</th>
            <td>
              {{ contract.nextContractEvaluationDate | date }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("distance_units") }}</th>
            <td>
              {{
                contract.distanceUnits
                  | distance(contract.systemOfMeasurement.name)
              }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("total_retail_value") }}</th>
            <td>{{ contract.totalRetailValue | money }}</td>
          </tr>
          <tr>
            <th>{{ $t("price_per_distance_unit") }}</th>
            <td>
              {{
                contract.pricePerDistanceUnit | money(contract.currency.code)
              }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("estimated_royalty") }}</th>
            <td>
              {{ contract.estimate | money(contract.currency.code) }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("full_insurance") }}</th>
            <td>
              {{ contract.fullInsurance ? $t("yes") : $t("no") }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("sellback_expiration_date") }}</th>
            <td>
              {{ contract.sellbackExpirationDate | date }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("sellback_option") }}</th>
            <td>
              {{ contract.sellbackOption ? $t("yes") : $t("no") }}
            </td>
          </tr>
          <tr v-if="contract.sellbackOption">
            <th>{{ $t("sellback_price") }}</th>
            <td>
              {{ contract.sellbackPrice | money(contract.currency.code) }}
            </td>
          </tr>
          <tr>
            <th>{{ $tc("currency", 1) }}</th>
            <td>{{ contract.currency.name }} ({{ contract.currency.code }})</td>
          </tr>
          <tr>
            <th>{{ $tc("system_of_measurement", 1) }}</th>
            <td>{{ contract.systemOfMeasurement.name }}</td>
          </tr>
        </table>

        <Devices :contract="contract" @submit="load"></Devices>

        <Partners v-if="userHasRoleSuperAdmin" :contract="contract"></Partners>
      </div>
    </div>

    <b-modal
      v-if="userHasRoleSuperAdmin"
      v-model="showContractEndDateModal"
      :width="400"
      scroll="keep"
    >
      <ContractEndDateModal
        :contract="contract"
        @submit="load"
      ></ContractEndDateModal>
    </b-modal>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";
import BackButton from "../../components/BackButton";
import Partners from "../../components/Partners";
import ContractEndDateModal from "./partials/ContractEndDateModal";
import Devices from "./partials/Devices";

export default {
  name: "ContractView",
  components: {
    BackButton,
    ContractEndDateModal,
    Devices,
    Partners,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      contract: null,
      showContractEndDateModal: false,
    };
  },
  computed: {
    ...mapGetters([
      "findCurrencyByUri",
      "findSystemOfMeasurementByUri",
      "userHasRoleSuperAdmin",
    ]),
    expired() {
      if (!this.contract) {
        return;
      }
      return dayjs(this.contract.endDate).isBefore(dayjs(), "day");
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getContract"]),
    load() {
      this.loading = true;

      this.getContract(this.id).then(async (contract) => {
        contract.estimate =
          contract.pricePerDistanceUnit * contract.distanceUnits;
        contract.currency = this.findCurrencyByUri(contract.currency["@id"]);
        contract.systemOfMeasurement = this.findSystemOfMeasurementByUri(
          contract.systemOfMeasurement["@id"]
        );
        this.contract = contract;

        this.loading = false;
      });
    },
    renew() {
      this.$router.push({
        name: "ContractCreate",
        query: { renew: this.contract.id },
      });
    },
  },
};
</script>
